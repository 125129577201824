document.addEventListener("turbolinks:load", () => {
    let controller = $('#controller').data('source');
    if (controller === 'output/update_data_manual') {
        $('.update').addClass('active');
        $('#update_apply').on('click', function (e) {
            $('#data_update')[0].submit();
        });
        $('#update_focus_apply').on('click', function (e) {
            $('#data_focus_update')[0].submit();
        });
        $('#delete_apply').on('click', function (e) {
            $('#data_delete')[0].submit();
        });
        $('#delete_focus_apply').on('click', function (e) {
            $('#data_focus_delete')[0].submit();
        });
    }
});
