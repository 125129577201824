document.addEventListener("turbolinks:load", () => {
    let controller = $('#controller').data('source');
    if (controller === 'output/upload_observation') {
        $('.observation').addClass('active');
        $('#file_apply').on('click', function (e) {
            //if ($("#file_data")[0].files.length !== 0 && $('#dateSelect').value !== undefined) {
            if ( $('#file_data') && $('#dateSelect')) {
                $('#file_import')[0].submit();
           }
	  else{
	console.log("Not submit");
	  }
        });

        $('#file_reset').on('click', function (e) {
            $('#file_import')[0].reset();
        });
    }
});
