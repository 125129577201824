document.addEventListener("turbolinks:load", () => {
    let controller = $('#controller').data('source');
    let report_type = $('#report_type').data('source');
    let image_urls = $('#image_urls').data('source');
    let center = [27.5, 90.5];

    function dataNotAvailable(map){
        L.Control.textbox = L.Control.extend({
            onAdd: function (map) {
                let text = L.DomUtil.create('div');
                text.id = "map_title";
                text.className = "info legend";
                text.innerHTML = "<div style='font-size: 22px'><strong>Data Not Available</strong></div>";
                return text;
            },

            onRemove: function (map) {
                // Nothing to do here
            }
        });

        L.control.textbox = function (opts) {
            return new L.Control.textbox(opts);
        };

        L.control.textbox({position: 'topright'}).addTo(map);
    }

    function addImgtoMap(i) {
        let baselayer = L.tileLayer(
            'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
                maxZoom: 15
            });
        let map = L.map('map' + i, {
            center: center,
            zoom: 8,
            layers: [baselayer]
        });

        if (image_urls !== undefined) {
            let imageUrl = image_urls[("mme" + i)],
                imageBounds = [[28.55, 88.45], [26.45, 92.55]];

            if (imageUrl === 'N/A') {
                dataNotAvailable(map)
            } else {
                let overlay = L.imageOverlay(imageUrl, imageBounds, {opacity: 0.7}).addTo(map);
                overlay.bringToFront();
            }
        } else {
            dataNotAvailable(map)
        }

        L.latlngGraticule({
            showLabel: true,
            font: '12px Arial',
            dashArray: [5, 5],
            fontColor: 'black',
            color: 'rgb(0,0,0,0.4)',
            zoomInterval: [
                {start: 1, end: 3, interval: 10},
                {start: 4, end: 6, interval: 5},
                {start: 7, end: 9, interval: 1},
                {start: 10, end: 12, interval: 0.5},
                {start: 13, end: 15, interval: 0.1}
            ]
        }).addTo(map);

    }

    if (controller === 'focus/index') {
        $('.focus').addClass('active');
        $('#focusmenu').addClass('show');
        $('#focusmenu li[class="' + report_type + '"]').addClass('active');

        for (let i = 1; i < 3; i++) {
            addImgtoMap(i);
        }
    }
});