document.addEventListener("turbolinks:load", () => {
    let controller = $('#controller').data('source');
    let geoJsonObs = $('#observed_data').data('source');
    let geoJsonWeek = $('#weekly_data').data('source');
    let geoJsonForecast = $('#forecast_data').data('source');
    let geoJsonAnomaly = $('#anomaly_data').data('source');
    let geoJsonOutlook = $('#outlook_data').data('source');
    let bhutan_geoJson = $('#bhutan_data').data('source');
    let report_type = $('#report_type').data('source');

    let center = [27.2, 90.4336];

// Create the map
    let mapForecastArr = {};
    let mapAnomalyArr = {};
    let mapOutlookArr = {};

    function getRainColor(d) {
        return d > 300 ? 'rgb(102,102,102)' :
            d > 200 ? 'rgb(30,60,255)' :
                d > 100 ? 'rgb(130,0,220)' :
                    d > 50 ? 'rgb(240,0,130)' :
                        d > 20 ? 'rgb(250,60,60)' :
                            d > 10 ? 'rgb(240,130,40)' :
                                d > 8 ? 'rgb(230,175,45)' :
                                    d > 6 ? 'rgb(230,220,50)' :
                                        d > 4 ? 'rgb(160,230,50)' :
                                            d > 2 ? 'rgb(0,220,0)' :
                                                d > 1 ? 'rgb(0,210,140)' :
                                                    'rgba(255,255,255,0.3)';
    }

    function getTempColor(d) {
        return d > 40 ? 'rgb(180,140,130)' :
            d > 39 ? 'rgb(160,120,110)' :
                d > 38 ? 'rgb(140,100,90)' :
                    d > 37 ? 'rgb(80,60,0)' :
                        d > 36 ? 'rgb(100,60,0)' :
                            d > 35 ? 'rgb(120,60,0)' :
                                d > 34 ? 'rgb(135,60,0)' :
                                    d > 33 ? 'rgb(145,55,0)' :
                                        d > 32 ? 'rgb(155,50,0)' :
                                            d > 31 ? 'rgb(165,45,0)' :
                                                d > 30 ? 'rgb(175,40,0)' :
                                                    d > 29 ? 'rgb(195,30,0)' :
                                                        d > 28 ? 'rgb(215,20,0)' :
                                                            d > 27 ? 'rgb(235,10,0)' :
                                                                d > 26 ? 'rgb(255,0,0)' :
                                                                    d > 25 ? 'rgb(255,33,33)' :
                                                                        d > 24 ? 'rgb(255,60,50)' :
                                                                            d > 23 ? 'rgb(255,74,50)' :
                                                                                d > 22 ? 'rgb(255,93,50)' :
                                                                                    d > 21 ? 'rgb(255,118,50)' :
                                                                                        d > 20 ? 'rgb(255,127,50)' :
                                                                                            d > 19 ? 'rgb(255,146,50)' :
                                                                                                d > 18 ? 'rgb(255,161,50)' :
                                                                                                    d > 17 ? 'rgb(255,185,50)' :
                                                                                                        d > 16 ? 'rgb(255,204,50)' :
                                                                                                            d > 15 ? 'rgb(255,209,50)' :
                                                                                                                d > 14 ? 'rgb(255,224,50)' :
                                                                                                                    d > 13 ? 'rgb(255,233,50)' :
                                                                                                                        d > 12 ? 'rgb(253,255,50)' :
                                                                                                                            d > 11 ? 'rgb(224,255,50)' :
                                                                                                                                d > 10 ? 'rgb(204,255,50)' :
                                                                                                                                    d > 9 ? 'rgb(171,255,50)' :
                                                                                                                                        d > 8 ? 'rgb(132,255,50)' :
                                                                                                                                            d > 7 ? 'rgb(79,255,50)' :
                                                                                                                                                d > 6 ? 'rgb(50,255,60)' :
                                                                                                                                                    d > 5 ? 'rgb(50,255,79)' :
                                                                                                                                                        d > 4 ? 'rgb(50,255,103)' :
                                                                                                                                                            d > 3 ? 'rgb(50,255,132)' :
                                                                                                                                                                d > 2 ? 'rgb(50,255,161)' :
                                                                                                                                                                    d > 1 ? 'rgb(50,255,200)' :
                                                                                                                                                                        d > 0 ? 'rgb(50,255,228)' :
                                                                                                                                                                            d > -1 ? 'rgb(50,255,253)' :
                                                                                                                                                                                d > -2 ? 'rgb(50,224,255)' :
                                                                                                                                                                                    d > -3 ? 'rgb(50,204,255)' :
                                                                                                                                                                                        d > -4 ? 'rgb(50,175,255)' :
                                                                                                                                                                                            d > -5 ? 'rgb(50,146,255)' :
                                                                                                                                                                                                d > -6 ? 'rgb(50,113,255)' :
                                                                                                                                                                                                    d > -7 ? 'rgb(50,89,255)' :
                                                                                                                                                                                                        d > -8 ? 'rgb(50,74,255)' :
                                                                                                                                                                                                            d > -9 ? 'rgb(50,50,255)' :
                                                                                                                                                                                                                d > -10 ? 'rgb(79,50,255)' :
                                                                                                                                                                                                                    'rgba(98,74,255,0.31)';
    }

    function getHumidColor(d) {
        return d > 100 ? 'rgb(102,102,102)' :
            d > 90 ? 'rgb(30,60,255)' :
                d > 80 ? 'rgb(130,0,220)' :
                    d > 70 ? 'rgb(240,0,130)' :
                        d > 60 ? 'rgb(250,60,60)' :
                            d > 50 ? 'rgb(240,130,40)' :
                                d > 40 ? 'rgb(230,175,45)' :
                                    d > 30 ? 'rgb(230,220,50)' :
                                        d > 20 ? 'rgb(160,230,50)' :
                                            d > 10 ? 'rgb(0,220,0)' :
                                                d > 0 ? 'rgb(0,210,140)' :
                                                    'rgba(255,255,255,0.3)';
    }

    function getAnomalyColor(d) {
        return d > 30 ? 'rgb(0,0,255)' :
            d > 20 ? 'rgb(40,40,255)' :
                d > 15 ? 'rgb(80,80,255)' :
                    d > 10 ? 'rgb(120,120,255)' :
                        d > 5 ? 'rgb(160,160,255)' :
                            d > 1 ? 'rgb(200,200,255)' :
                                d > 0 ? 'rgb(255,255,255)' :
                                    d > -1 ? 'rgb(255,200,200)' :
                                        d > -5 ? 'rgb(255,160,160)' :
                                            d > -10 ? 'rgb(255,120,120)' :
                                                d > -15 ? 'rgb(255,80,80)' :
                                                    d > -20 ? 'rgb(255,40,40)' :
                                                        'rgba(255,0,0)';
    }

    function onEachFeature(feature, layer) {
        let popupContent = '<p> Value: ' + feature.properties.main_var + '</p>';

console.log(typeof(feature.properties.main_var));

        layer.on('mouseover', function (e) {
            if (!layer.isPopupOpen()) {
                layer.bindPopup(popupContent, {autoPan: false}).openPopup();
            }
        });

        layer.on('mouseout', function (e) {
            layer.closePopup();
        });
    }

    function style(feature) {
        let data_var = feature.properties.data_var;
        let main_var = feature.properties.main_var;
        if (main_var.toString() !== 'N/A')
            return {
                fillColor: data_var === 'tp' ? getRainColor(main_var) : ((data_var === 'tmax' || data_var === 'tmin') ? getTempColor(main_var) : getHumidColor(main_var)),
                weight: 1,
                opacity: 0.6,
                color: 'white',
                dashArray: '3',
                fillOpacity: 0.9
            };
        else
            return {
                fillColor: 'none',
                weight: 2,
                opacity: 1,
                color: 'black'
            };
    }

    function anomalyStyle(feature) {
        let data_var = feature.properties.data_var;
        let main_var = feature.properties.main_var;
        return {
            fillColor: getAnomalyColor(main_var),
            weight: 1,
            opacity: 0.6,
            color: 'white',
            dashArray: '3',
            fillOpacity: 0.9
        };
    }

    function init_carousel(carousel_id, nav_section, mapArr) {
        // Activate carousel
        $(carousel_id).carousel({
            pause: true,
            interval: false
        });

        $(carousel_id).on('slid.bs.carousel', function () {
            let id = $(this).find("div.active").find('div[id^="map"]')[0].id;
            let map = mapArr[id].mapObj;
            map.invalidateSize();
        });

        $((nav_section + ' .weekNav .nav-item')).children().each(function (index) {
            $(this).click(function () {
                $((nav_section + ' .weekNav .nav-item')).children().removeClass('active');
                $(this).addClass('active');
                $(carousel_id).carousel(index);
            })
        });
    }

    function addMap(i, geoJson, map_for, style) {
        let baselayer = L.tileLayer(
            'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
                maxZoom: 15
            });

        let geojsonlayer = L.geoJson(bhutan_geoJson, {
            style: {
                "color": "#000000",
                "opacity": 0.5,
                "fill": false,
                "weight": 2
            }
        });

        let geoData = geoJson;

        let geojsonheatlayer = L.geoJson(geoData, {style: style, onEachFeature: onEachFeature});

        let map = L.map('map' + i, {
            center: center,
            zoom: 7,
	    scrollWheelZoom: false,
            layers: [baselayer, geojsonheatlayer, geojsonlayer]
        });

        let legend = L.control({position: 'bottomright'});

        let data_var = geoData.features[0].properties.data_var;

        if (map_for === 'anomaly') {
            legend.onAdd = function (map) {

                let div = L.DomUtil.create('div', 'info legend'),
                    grades = [-30, -20, -15, -10, -5, -1, 0, 1, 5, 10, 15, 20, 30],
                    labels = [];

                // loop through our density intervals and generate a label with a colored square for each interval

                for (let i = 0; i < grades.length; i++) {
                    div.innerHTML +=
                        '<i style="background:' + getAnomalyColor(grades[i] + 1) + '"></i> ' +
                        grades[i] + '<br>';
                }

                return div;
            };
        } else {
            legend.onAdd = function (map) {

                let div = L.DomUtil.create('div', 'info legend'),
                    grades = data_var === 'tp' ? [1, 2, 4, 6, 8, 10, 20, 50, 100, 200, 300] : ((data_var === 'tmax' || data_var === 'tmin') ? [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40] : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
                    labels = [];

                // loop through our density intervals and generate a label with a colored square for each interval
                if (data_var === 'tmax' || data_var === 'tmin') {
                    let lowerHTML = '<div class="row"><div class="col">';
                    let upperHTML = '<div class="row"><div class="col">';
                    let counter = 0;
                    for (let i = 0; i < grades.length; i++) {

                        if ((grades[i]) >= geoData.min && (grades[i]) <= geoData.max) {
                            if (counter % 5 === 0) {
                                upperHTML += '<i class="tempr" style="background: unset;font-style: normal">' + (grades[i]) + '</i>';
                            } else {
                                upperHTML += '<i class="tempr" style="background: unset"></i>';
                            }
                            counter++;
                            lowerHTML +=
                                '<i class="tempr" style="background:' + getTempColor(grades[i]) + '"></i>';
                        }
                    }
                    lowerHTML += '</div></div>';
                    upperHTML += '</div></div>';
                    div.innerHTML = upperHTML + lowerHTML
                } else {
                    for (let i = 0; i < grades.length; i++) {
                        div.innerHTML +=
                            '<i style="background:' + (data_var === 'tp' ? getRainColor(grades[i] + 1) : getHumidColor(grades[i] + 1)) + '"></i> ' +
                            grades[i] + '<br>';
                    }
                }

                return div;
            };
        }

        legend.addTo(map);

        L.latlngGraticule({
            showLabel: true,
            font: '12px Arial',
            dashArray: [5, 5],
            fontColor: 'black',
            color: 'rgb(0,0,0,0.4)',
            zoomInterval: [
                {start: 1, end: 3, interval: 10},
                {start: 4, end: 6, interval: 5},
                {start: 7, end: 9, interval: 1},
                {start: 10, end: 12, interval: 0.5},
                {start: 13, end: 15, interval: 0.1}
            ]
        }).addTo(map);

        L.Control.textbox = L.Control.extend({
            onAdd: function (map) {
                let text = L.DomUtil.create('div');
                text.id = "map_title";
                text.className = "info legend";
                text.innerHTML = "<div style='font-size: 22px'><strong>" + geoData.feature_name + "</strong><br><strong style='float: right'>" + geoData.features[0].properties.time + "</strong></div>";
                return text;
            },

            onRemove: function (map) {
                // Nothing to do here
            }
        });

        L.control.textbox = function (opts) {
            return new L.Control.textbox(opts);
        };

        L.control.textbox({position: 'topright'}).addTo(map);

        return map
    }

    function addObsMap(i, geoJson, map_for, style) {
        let baselayer = L.tileLayer(
            'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
                maxZoom: 15
            });

        let geoData = geoJson;

        let geojsonheatlayer = L.geoJson(geoData, {style: style, onEachFeature: onEachFeature});

        let map = L.map('map' + i, {
            center: center,
            zoom: 7,
	    scrollWheelZoom: false,
            layers: [baselayer, geojsonheatlayer]
        });

        let legend = L.control({position: 'bottomright'});

        let data_var = geoData.features[0].properties.data_var;

        legend.onAdd = function (map) {

            let div = L.DomUtil.create('div', 'info legend'),
                grades = data_var === 'tp' ? [1, 2, 4, 6, 8, 10, 20, 50, 100, 200, 300] : ((data_var === 'tmax' || data_var === 'tmin') ? [-10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40] : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]),
                labels = [];

            // loop through our density intervals and generate a label with a colored square for each interval
            if (data_var === 'tmax' || data_var === 'tmin') {
                let lowerHTML = '<div class="row"><div class="col">';
                let upperHTML = '<div class="row"><div class="col">';
                let counter = 0;
                for (let i = 0; i < grades.length; i++) {

                    if ((grades[i]) >= geoData.min && (grades[i]) <= geoData.max) {
                        if (counter % 5 === 0) {
                            upperHTML += '<i class="tempr" style="background: unset;font-style: normal">' + (grades[i]) + '</i>';
                        } else {
                            upperHTML += '<i class="tempr" style="background: unset"></i>';
                        }
                        counter++;
                        lowerHTML +=
                            '<i class="tempr" style="background:' + getTempColor(grades[i]) + '"></i>';
                    }
                }
                lowerHTML += '</div></div>';
                upperHTML += '</div></div>';
                div.innerHTML = upperHTML + lowerHTML
            } else {
                for (let i = 0; i < grades.length; i++) {
                    div.innerHTML +=
                        '<i style="background:' + (data_var === 'tp' ? getRainColor(grades[i] + 1) : getHumidColor(grades[i] + 1)) + '"></i> ' +
                        grades[i] + '<br>';
                }
            }

            return div;
        };

        legend.addTo(map);

        L.latlngGraticule({
            showLabel: true,
            font: '12px Arial',
            dashArray: [5, 5],
            fontColor: 'black',
            color: 'rgb(0,0,0,0.4)',
            zoomInterval: [
                {start: 1, end: 3, interval: 10},
                {start: 4, end: 6, interval: 5},
                {start: 7, end: 9, interval: 1},
                {start: 10, end: 12, interval: 0.5},
                {start: 13, end: 15, interval: 0.1}
            ]
        }).addTo(map);

        L.Control.textbox = L.Control.extend({
            onAdd: function (map) {
                let text = L.DomUtil.create('div');
                text.id = "map_title";
                text.className = "info legend";
                text.innerHTML = "<div style='font-size: 22px'><strong>" + geoData.feature_name + "</strong><br><strong style='float: right'>" + geoData.features[0].properties.time + "</strong></div>";
                return text;
            },

            onRemove: function (map) {
                // Nothing to do here
            }
        });

        L.control.textbox = function (opts) {
            return new L.Control.textbox(opts);
        };

        L.control.textbox({position: 'topright'}).addTo(map);

        return map
    }

    //MAP CREATION
    if (controller === 'output/indexWeek') {
        $('.s2sforecast').addClass('active');
        $('#reportsmenu').addClass('show');
        $('#reportsmenu li[class="' + report_type + '"]').addClass('active');

        //Observed
        addObsMap("0", geoJsonObs, "", style);

        //Weekly
        addObsMap("00", geoJsonWeek, "", style);

        //Forecast
        for (let i = 1; i < 5; i++) {

            let geoJson = JSON.parse(geoJsonForecast[i - 1]);

            let map = addMap(("" + i), geoJson, "", style);

            mapForecastArr[('map' + i)] = {
                mapObj: map
            }
        }

        //Anomaly
        for (let i = 1; i < 5; i++) {

            let geoJson = JSON.parse(geoJsonAnomaly[i - 1]);

            let map = addMap(("" + i + i), geoJson, "anomaly", anomalyStyle);

            mapAnomalyArr[('map' + i + i)] = {
                mapObj: map
            }
        }

        //Outlook
        for (let i = 1; i < 5; i++) {

            let geoJson = JSON.parse(geoJsonOutlook[i - 1]);

            let map = addObsMap(("" + i + i + i), geoJson, "", style);

            mapOutlookArr[('map' + i + i + i)] = {
                mapObj: map
            }
        }

        init_carousel("#forecastCarousel", ".forecast-section", mapForecastArr);
        init_carousel("#anomalyCarousel", ".anomaly-section", mapAnomalyArr);
        init_carousel("#outlookCarousel", ".outlook-section", mapOutlookArr);
    }
});
