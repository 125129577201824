document.addEventListener("turbolinks:load", () => {
    let controller = $('#controller').data('source');
    global.chart_data = $('#chart_data').data('source');
    let bhutan_geoJson = $('#bhutan_data').data('source');

    if (controller === 'dzongkha/index') {
        $('.dzongkhag').addClass('active');
        let center = [27.2, 90.4336];

        function highlightFeature(e) {
            let layer = e.target;

            layer.setStyle({
                weight: 5,
                color: '#000000',
                fillColor: 'unset',
                fillOpacity: 0
            });

            if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                layer.bringToFront();
            }

            let popupContent = '<p> Dzongkhag Name: ' + layer.feature.properties.DIST_NAME + '<br> Dzongkhag Code: ' + layer.feature.properties.DIST_CODE + '</p>';

            if (!layer.isPopupOpen()) {
                layer.bindPopup(popupContent, {autoPan: false}).openPopup();
            }
        }

        function resetHighlight(e) {
            geojsonlayer.resetStyle(e.target);
            e.target.closePopup();
        }

        function zoomToFeature(e) {
            let layer = e.target;
            map.fitBounds(layer.getBounds());
            // $('#dateFilter').find('input[name="dzongkha_name"]')[0].value = layer.feature.properties.DIST_NAME;
            $.ajax({
                url: '/dzongkha/analysistable',
                type: 'post',
                data: {
                    dzongkha_name: layer.feature.properties.DIST_NAME,
                    dateSelect: $('#date_select').data('source')
                }

            });

            $(('.analysisNav .nav-item')).children().removeClass('active');
            $('.analysisNav .nav-item .table-btn').addClass('active');
        }

        function onEachFeature(feature, layer) {
            layer.on({
                mouseover: highlightFeature,
                mouseout: resetHighlight,
                click: zoomToFeature
            });
        }


        let baselayer = L.tileLayer(
            'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
                maxZoom: 15
            });

        let geojsonlayer = L.geoJson(bhutan_geoJson, {
            style: {
                "color": "#000000",
                "opacity": 0.5,
                "fillOpacity": 0,
                "fillColor": "unset",
                "weight": 2
            },
            onEachFeature: onEachFeature
        });

        let map = L.map('mapD', {
            center: center,
            zoom: 8,
	    scrollWheelZoom: false,
            layers: [baselayer, geojsonlayer]
        });

        global.table = $('#analysistable').DataTable({
            autowidth: false,
            "oLanguage": {
                "sLengthMenu": "_MENU_ indices",
                "sInfo": "_PAGE_ of _PAGES_ page(s)",
                "sSearch": "Search Indices:"
            },
            "displayLength": 25,
            "columns": [
                {"searchable": true},
                {"searchable": false, "visible": false},
                {"searchable": false},
                {"searchable": false},
                {"searchable": false},
                {"searchable": false}
            ],
            "order": [[1, "asc"]],
            "drawCallback": function (settings) {
                let api = this.api();
                let rows = api.rows({page: 'current'}).nodes();
                let last = null;

                api.column(1, {page: 'current'}).data().each(function (group, i) {
                    if (last !== group) {
                        $(rows).eq(i).before(
                            '<tr class="group"><td colspan="5">' + group + '</td></tr>'
                        );

                        last = group;
                    }
                });
            }
        });

        $('#analysistable tbody').on('click', 'tr.group', function () {
            var currentOrder = table.order()[0];
            if (currentOrder[0] === 1 && currentOrder[1] === 'asc') {
                table.order([1, 'desc']).draw();
            } else {
                table.order([1, 'asc']).draw();
            }
        });

        global.anomalytable = $('#anomalytable').DataTable({
            autowidth: false,
            "oLanguage": {
                "sLengthMenu": "_MENU_ reports",
                "sInfo": "_PAGE_ of _PAGES_ page(s)",
                "sSearch": "Search:"
            },
            "displayLength": 10,
            "columns": [
                {"searchable": true},
                {"searchable": false},
                {"searchable": false},
                {"searchable": false},
                {"searchable": false}
            ],
            "order": [[0, "asc"]]
        });

        for (let i = 0; i < chart_data.length; i++) {
            Highcharts.chart("chart" + i, chart_data[i]);
        }

        $('.table-btn').on('click', function () {
            $('.d-anomaly-section').removeClass('active');
            $('.analysis-section').removeClass('active');
            $('.table-section').addClass('active');
            $('.index_triggers').addClass('active');
        });
        $('.anomaly-btn').on('click', function () {
            $('.table-section').removeClass('active');
            $('.index_triggers').removeClass('active');
            $('.analysis-section').removeClass('active');
            $('.d-anomaly-section').addClass('active');
        });
        $('.analysis-btn').on('click', function () {
            $('.table-section').removeClass('active');
            $('.index_triggers').removeClass('active');
            $('.d-anomaly-section').removeClass('active');
            $('.analysis-section').addClass('active');
        });

        $(('.analysisNav .nav-item')).children().each(function (index) {
            $(this).click(function () {
                $(('.analysisNav .nav-item')).children().removeClass('active');
                $(this).addClass('active');
            })
        });

        $('[data-toggle="tooltip"]').tooltip();

        // $('.analysis-datepicker .input-daterange').datepicker({});
        $('.analysis-datepicker .input-daterange input').each(function () {
            $(this).datepicker({
                weekStart: 4
            });
        });
    }
});
