let listtable = null;
document.addEventListener("turbolinks:load", () => {
    let controller = $('#controller').data('source');

    if (controller === 'dzongkha_data/index') {
        listtable = $('#listtable').DataTable({
            autowidth: false,
            "oLanguage": {
                "sLengthMenu": "_MENU_ Dzongkhags",
                "sInfo": "_PAGE_ of _PAGES_ page(s)",
                "sSearch": "Dzongkhag Search:"
            },
            "columns": [
                {"searchable": true},
                {"searchable": true},
                {"searchable": false},
                {"searchable": false},
                {"searchable": false},
            ]
        });
    }

});

document.addEventListener("turbolinks:before-cache", () => {
    if ($('#listtable_wrapper').length === 1) {
        listtable.destroy()
    }
});