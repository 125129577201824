// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'jquery';
import $ from 'jquery';

global.$ = jQuery;
import 'popper.js';
import "bootstrap";
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import './stylesheets/application';
import "@fortawesome/fontawesome-free/js/all";
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/js/dataTables.dataTables.min"
import bsCustomFileInput from "bs-custom-file-input";

global.bsCustomFileInput = bsCustomFileInput;
import './leaflet.latlng-graticule';
import Highcharts from 'highcharts';

global.Highcharts = Highcharts;
import Exporting from 'highcharts/modules/exporting';

Exporting(Highcharts);
import "bootstrap-datepicker"
import './output';
import './dzongkha';
import './dzongkha_data';
import './user_data';
import './focus'
import './observation'
import './booklet'
import './manual_update'

document.addEventListener("turbolinks:load", () => {
    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });

    bsCustomFileInput.init();

    setTimeout(function () {
        $('#notice_wrapper').fadeOut("slow", function () {
            $(this).remove();
        });
    }, 3000);

});